import Link from 'next/link';
import Image from 'next/image';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { ASSET_IMG_PLACEHOLDER } from 'constants/settings';
import PAGES from 'constants/links/pages';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    background: theme.palette.background.card,
    textAlign: 'center',
    transition: 'ease-in-out 0.25s',
    '& p': {
      fontSize: 14,
    },
    '&:hover': {
      filter: 'contrast(120%)',
      boxShadow: 'inset -5px 2px 32px -9px #06bcff',
    },
  },
  assetImg: {
    objectFit: 'contain', // props => (props.displayStyle || 'cover'),
    width: '100%',
    height: 180,
  },
  listedRect: {
    position: 'absolute',
    color: theme.palette.text.success,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.text.success,
    borderRadius: 3,
    fontSize: `12px !important`,
    padding: theme.spacing(0, 0.5),
    right: 0,
    textTransform: 'capitalize',
  },
}));

function AssetCard({ data }) {
  const classes = useStyles({ displayStyle: data?.collection?.display_data?.card_display_style });
  const tokenId = data.tokenId || data.nftCollateralId || data.token_id;

  return (
    <Link href={`${PAGES.ASSETS.url}/${tokenId}`}>
      <a target='_blank'>
        <Card className={classes.root}>
          {data?.animationUrl ? (
            <video
              width='100%'
              height='100%'
              autoPlay
              loop
              muted
              playsInline
              className={classes.assetImg}>
              <source src={data?.animationUrl} type='video/mp4' />
            </video>
          ) : (
            <Image
              width={240}
              height={200}
              layout='responsive'
              className={classes.assetImg}
              src={
                data.image_preview_url ||
                `https://img.syncbond.com/bond/${tokenId}_sm.png` ||
                ASSET_IMG_PLACEHOLDER
              }
              alt='asset'
            />
          )}
          <Box position='relative' display='flex' alignItems='center' justifyContent='center'>
            <Typography noWrap>{data?.projectName || data?.collection?.name || 'CBOND'}</Typography>
            {data?.status && <Typography className={classes.listedRect}>{data?.status}</Typography>}
          </Box>
          <Typography noWrap>{data?.name || 'SYNC Cryptobond'}</Typography>
        </Card>
      </a>
    </Link>
  );
}

export default AssetCard;
