import { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LendBorrowHOC from 'hoc/LendBorrowHOC';
import AssetCard from 'components/AssetCard';
import LoadingBar from 'components/UI/LoadingBar';
import { getAssetsByAddress } from 'services/opensea';
import { getListingsByAccount } from 'services/nftfi';
import { compareByStatus } from 'utils/utility';
import { Typography } from '@material-ui/core';

function BorrowPage({ address }) {
  const [assetList, setAssetList] = useState([]);
  const [listedAssetArray, setListedAssetArray] = useState([]);
  const [assetListWithStatus, setAssetListWithStatus] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(true);
  const [page, setPage] = useState(0);
  const [isFinal, setIsFinal] = useState(false);
  const loader = useRef(null);

  const fetchMyAssets = async () => {
    try {
      setLoadingAssets(true);
      // TODO: test account for pagination 0x5ed7d8e2089b2b0e15439735b937cec5f0ae811b (test)
      const result = await getAssetsByAddress(address, page);

      if (result.length) {
        setAssetList(assetList => [...assetList, ...result]);
      } else {
        setIsFinal(true);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingAssets(false);
  };

  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage(page => page + 1);
    }
  };

  const fetchListings = async () => {
    try {
      const result = await getListingsByAccount(address);
      setListedAssetArray(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const assetListWithStatus = assetList
      .map(asset => ({
        ...asset,
        status: listedAssetArray.find(
          listedAsset => listedAsset.nftCollateralId === asset.nftCollateralId,
        )?.status,
      }))
      .sort(compareByStatus);

    setAssetListWithStatus(assetListWithStatus);
  }, [listedAssetArray.length, assetList.length]);

  useEffect(() => {
    if (!address) {
      return;
    }
    fetchListings();

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [address]);

  useEffect(() => {
    // console.log('[page, isFinal, address] ==>', page, isFinal, address, 1);
    if (!isFinal && address) {
      fetchMyAssets();
    }
  }, [page, isFinal, address]);

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Typography variant='subtitle1'>My CryptoBonds</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems='stretch'>
            {assetListWithStatus.map((asset, index) => (
              <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                <AssetCard data={asset} />
              </Grid>
            ))}
            {loadingAssets && (
              <Grid item xs={12} key='loading' align='center'>
                <LoadingBar />
              </Grid>
            )}
            {!loadingAssets && assetListWithStatus.length === 0 && (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='100%'
                height={300}
                p={2}>
                <Typography align='center'>
                  You dont have any bonds. You can offer loans on bonds that were already created,
                  or click &apos;Create&apos; to make your own bond.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div ref={loader} />
    </>
  );
}

export default LendBorrowHOC(BorrowPage, false);
