import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  loadingBars: {
    position: 'relative',
    display: 'inline-block',
    margin: theme.spacing(0, 1),
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    marginLeft: theme.spacing(5 / 8),
    verticalAlign: 'revert',
  },
  line: {
    width: theme.spacing(1),
    position: 'absolute',
    borderRadius: theme.spacing(0.5),
    bottom: 0,
    background: `-webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#505DD4),
      to(#24BBD0)
    )`,
    background: `-webkit-linear-gradient(top, #24BBD0, #505DD4)`,
    background: `-o-linear-gradient(top, #24BBD0, #505DD4)`,
    background: `linear-gradient(to bottom, #24BBD0, #505DD4)`,
  },
  line1: {
    left: 0,
    animation: `$lineGrow 0.5s ease alternate infinite`,
  },
  line2: {
    left: theme.spacing(2.5),
    animation: `$lineGrow 0.5s 0.2s ease alternate infinite`,
  },
  line3: {
    left: theme.spacing(5),
    animation: `$lineGrow 0.5s 0.4s ease alternate infinite`,
  },
  '@keyframes lineGrow': {
    '0%': {
      height: 0,
    },
    '100%': {
      height: '75%',
    },
  },
}));

export default function LoadingBar({ size = 40 }) {
  const classes = useStyles({ size });

  return (
    <div className={classes.loadingBars}>
      <div className={clsx(classes.line, classes.line1)} />
      <div className={clsx(classes.line, classes.line2)} />
      <div className={clsx(classes.line, classes.line3)} />
    </div>
  );
}
