import axios from 'axios';
import { OPENSEA_API, COUNTS_ON_PAGE } from 'config/settings';
import { CBondContractAddress } from 'config/contracts';

// TODO: update with pagination later
export const getAssetsByAddress = async (address, page = 0) => {
  const result = await axios.get(
    `${OPENSEA_API}/assets?owner=${address}&collection=cbond&offset=${
      page * COUNTS_ON_PAGE
    }&limit=${COUNTS_ON_PAGE}`,
  );
  console.log('result ==>', result);
  return result.data.assets;
  // return await axios.get(`${OPENSEA_API}/assets?owner=${address}&collection=cbond`);
};

export const getCBondAssets = async (page = 0) => {
  const result = await axios.get(
    `${OPENSEA_API}/assets?offset=${
      page * COUNTS_ON_PAGE
    }&limit=${COUNTS_ON_PAGE}&collection=cbond`,
  );
  return result.data.assets;
};

export const getSingleAsset = async tokenId => {
  const result = await axios.get(`${OPENSEA_API}/asset/${CBondContractAddress}/${tokenId}`);
  return result.data;
};
