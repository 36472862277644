import { useContext, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useWeb3React } from '@web3-react/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ContainedButton from 'components/Buttons/ContainedButton';
import { AppContext } from 'contexts';

import { isEmpty } from 'utils/utility';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
  },
}));

export default function Home() {
  const classes = useStyles();

  const router = useRouter();
  const context = useWeb3React();
  const { account } = context;
  const { setIsWalletModalOpened } = useContext(AppContext);

  const connectWallet = () => {
    if (isEmpty(account)) {
      setIsWalletModalOpened(true);
    } else {
      setIsWalletModalOpened(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(account)) {
      router.back();
    }
  }, [account]);

  return (
    <div className={classes.root}>
      <Head>
        <title>Unlock Wallet</title>
      </Head>
      <Box display='flex' flexDirection='column' alignItems='center' p={1} maxWidth={600}>
        <Typography gutterBottom align='justify'>
          Welcome to the SYNC P2P Lending Platform! <br />
          <br />
          Get offers from other people in the SYNC Network on your CryptoBonds, or offer a loan on
          others CryptoBonds. You can also use this platform to buy/sell CryptoBonds. If the loan is
          not paid back the bond will be foreclosed to the lender, so dont make or accept any offers
          where you wouldn&apos;t be happy with receiving/losing the foreclosed asset.
          <br /> <br />
          The platform is powered by NFTFi.com smart contract, an NFT Lending platform. The contract
          is verified and open source, and has been internally audited by external developers,
          however a formal audit is currently pending.
          <br />
          <br />
          Please read our blog, literature, or contact us in our social media channels if you have
          trouble understanding.
          <br />
          <br />
          <br />
        </Typography>

        <ContainedButton onClick={connectWallet}>Connect to a Wallet</ContainedButton>
      </Box>
    </div>
  );
}
