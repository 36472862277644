import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useWeb3React } from '@web3-react/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from 'components/UI/Tabs';
import SyncCard from 'components/UI/SyncCard';
import { lendInfo, borrowInfo } from 'constants/labels';
import Unlock from 'pages/unlock';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

const LendBorrowHOC = function (WrappedComponent, isLend, isAsset) {
  const Container = props => {
    const classes = useStyles();
    const router = useRouter();
    const [activeTabIndex, setActiveTabIndex] = useState();

    const context = useWeb3React();
    const { account } = context;

    const displayData = isLend ? lendInfo : borrowInfo;

    useEffect(() => {
      const index = displayData.items.findIndex(item => item.url === router.pathname);
      if (index >= 0) {
        setActiveTabIndex(index);
      }
    }, [router.pathname]);

    if (!account) {
      return <Unlock />;
    }

    return (
      <div className={classes.root}>
        <SyncCard>
          <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
            <div>
              <Typography variant='h4' gutterBottom>
                {displayData.title}
              </Typography>
              <Typography>{displayData.description}</Typography>
            </div>
          </Box>
        </SyncCard>
        <Tabs
          items={displayData.items}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        />
        <WrappedComponent address={account} {...props} />
      </div>
    );
  };

  return Container;
};

export default LendBorrowHOC;
